<template>
    <div id="apps">
        <div class="myjinku">
            <van-nav-bar title="我的金库" left-arrow @click-left="$router.push('/usercenter')"/>
            <div class="hreads clear">
                <div class="tref">
                    <div class="left">
                        <p>我在猫着拼团平台总收益</p>
                        <span>{{info.totalIncomeAmout}}</span>
                    </div>
                    <div class="right">
                        <router-link to="/Drxinxi">
                            <span>团长信息</span>
                        </router-link>
                        <router-link to="/sqtixian">
                            <span>申请提现</span>
                        </router-link>
                    </div>
                </div>
                <div class="daye clear">
                    <ul>
                        <li>
                            <span>{{info.toWithdrawalAmout}}</span>
                            <p>可提现</p>
                        </li>
                        <router-link to="/ytxian">
                            <li>
                                <span>{{info.withdrawalFinishedAmout}}</span>
                                <p>已提现</p>
                            </li>
                        </router-link>
                        <router-link to="/txzhong">
                            <li>
                                <span>{{info.withdrawalProcessingAmout}}</span>
                                <p>提现中</p>
                            </li>
                        </router-link>
                    </ul>
                    <ul>
                        <li @click="search">
                            <span>{{info.selfBuyIncomeAmout}}</span>
                            <p>直卖收益</p>
                        </li>
                        <router-link to="/myteam">
                            <li>
                                <span>{{info.formTeamIncomeAmout}}</span>
                                <p>组建团队收益</p>
                            </li>
                        </router-link>
                        <router-link to="/shwtongguo">
                            <li>
                                <span>{{info.withdrawalAuditFailedAmout}}</span>
                                <p>提现未通过</p>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </div>
            <ul class="ul">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                    >
                        <li v-for="(item,index) in list">
                            <p>订单编号：{{item.orderNo}}</p>
                            <p>{{item.productName}}</p>
                            <div class="yongjin clear">
                                <font>佣金：{{item.totalCommission}}</font>
                                <span>
              姓名：{{item.userName}}<br/>
              联系电话：{{item.userPhone}}<br/>
              创建时间：{{item.createTime}}
             </span>
                            </div>
                        </li>
                    </van-list>
                </van-pull-refresh>
            </ul>
        </div>
    </div>
</template>
<script>
  import {getSelfBuyOrderList, userAccountStat} from "@/api/user";

  export default {
    name: 'app',
    components: {

    },
    data () {
      return {
        info: {},
        pageNum: 1,
        pages: 1,
        list: [],
        refreshing: false,
        loading: false,
        finished: false,
      }
    },
    methods: {
      loadBaseInfo () {
        userAccountStat().then(data => {
          if (data.success == true) {
            this.info = data.data
          }
        })
      },
      loadSelfOrder () {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return
        }
        this.loading = true;
        getSelfBuyOrderList({
          pageNum: this.pageNum,
        }).then(data => {
          if (data.success == true) {
            this.pageNum = data.data.current + 1;
            this.pages = data.data.pages;
            this.list.push(...data.data.records);
            if (this.pageNum > this.pages) {
              this.finished = true;
            }
          }
        }).finally(() => {
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      onRefresh () {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      onLoad () {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.pages = 1;
          this.refreshing = false;
        }
        this.loadSelfOrder()
      },
      search () {
        this.refreshing = true;
        this.onRefresh()
      }
    },
    created () {
      this.loadBaseInfo();
      this.loadSelfOrder();
    }
  }
</script>
<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    body {
        background: #F8F8F8;
        height: 100vh;
    }

    .daye ul li {
        width: 33.3%;
        float: left;
        color: #6D6D6D;
        text-align: center;
        font-size: 12px;
    }

    .daye ul {
        clear: both;
        overflow: hidden;
        border-bottom: solid 1px #F9F9F9;
        padding: 4% 0;
    }

    .daye {
        width: 90%;
        clear: both;
        overflow: hidden;
        border-radius: 4px;
        background: #fff;
        margin: -8% auto 3% auto;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(255, 255, 255, 100);
    }

    .daye ul li span {
        display: block;
        margin-bottom: 2%;
    }

    .hreads .tref {
        width: 100%;
        background: #E7402E;
        height: 20vh;
    }

    .hreads .tref .right {
        float: right;
        width: 20%;
    }

    .hreads .tref .left {
        float: left;
        width: 50%;
        color: #fff;
    }

    .hreads .tref .left p {
        font-size: 12px;
        display: block;
        margin: 12% 0 7% 5%;
    }

    .hreads .tref .left span {
        font-size: 25px;
        margin-left: 5%;
    }

    .hreads .tref .left span:before {
        margin-left: 1px;
        content: '¥';
        font-size: 12px;
        display: inline-block;
        margin-right: 2px;
    }

    .hreads .tref .right span {
        display: block;
        line-height: 20px;
        text-align: center;
        left: 319px;
        border-radius: 6px 0px 0px 6px;
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        font-family: -apple-system;
        background: #fff;
        margin-top: 20%;
        border: 1px solid rgba(255, 255, 255, 100);
    }

    .myjinku .ul {
        width: 100%;
        margin: 0 auto 5% auto;
        clear: both;
        overflow: hidden;
    }

    .myjinku .ul li {
        width: 80%;
        clear: both;
        overflow: hidden;
        border-radius: 4px;
        background: #fff;
        margin: 5% auto 8% auto;
        padding: 5%;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(255, 255, 255, 100);
    }

    .myjinku .ul li p {
        color: rgba(16, 16, 16, 100);
        font-size: 13px;
        display: block;
        line-height: 20px;
        font-family: 方正准圆-标准;
    }

    .myjinku .ul li .yongjin {
        border-top: 1px dotted rgba(187, 187, 187, 100);
        margin: 5% auto 1% auto;
    }

    .myjinku .ul li .yongjin span {
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        display: block;
        line-height: 20px;
        font-family: PingFangSC-regular;
    }

    .myjinku .ul li .yongjin font {
        color: rgba(223, 6, 6, 100);
        font-size: 12px;
        font-family: PingFangSC-regular;
        display: block;
        margin: 5% 0 2% 0;
    }
</style>
